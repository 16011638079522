.nav {
  width: 100vw;
  padding: 0 5%;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;

  display: flex;
  justify-content: space-between;
  align-items: center;

}
.stickyNav {
  position: sticky;
  top: 0;
  z-index: 50;
  background-color: #fff;
}
.navdisplay {
  position: fixed;
  right: 0;
  top: 0;
  flex-direction: column;
}
.logo {
  width: 5rem;
  height: 5rem;
}
.list {
  display: flex;
  gap: 10px;
}

.item {
  list-style: none;
  /* margin-right: 1.5rem; */
  padding-bottom: 0.5rem;
  position: relative;
}
.item::before {
  content: "";
  width: 0;
  height: 2px;
  background-color: #cd9933;
  position: absolute;
  left: 0;
  bottom: 0;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}
.item:hover::before {
  width: 100%;
}
.link:link,
.link:visited {
  text-decoration: none;
  text-transform: capitalize;
  color: #000000;
  font-size: 1.6rem;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}
.link:hover {
  color: #cd9933;
}

@media screen and (max-width: 768px) {
  .nav {
    display: flex;
    align-items: flex-start;
  }
  .list {
    flex-direction: column;
    top: 0;
    right: 0;
    height: 100vh;
    justify-content: flex-start;
    align-items: end;
    padding-top: 2rem;
    z-index: 20;
  }
  .item {
    margin-bottom: 2rem;
    width: 20%;
  }
  .link {
    color: #cd9933 !important;
  }
  .listdisplay {
    display: none;
  }
}
