.form {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.form-group {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.input {
  width: 80%;
  border: none;
  padding: 12px 10px;
  font-size: 1.6rem;
  position: relative;
}
.input:focus {
  outline: none;
}
.button {
  border: none;
  padding: 1rem 1.5rem;
  text-transform: capitalize;
  font-size: 1.6rem;
  background-color: #4d4d4d;
  color: #fff;
}
.button:active {
  background-color: #000;
}

.label {
  background-color: #000;
  padding: 10px;
  color: #fff;
  font-size: 1.6rem;
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}
.input::placeholder {
  font-size: 1rem;
  text-transform: capitalize;
}

.input:focus ~ .label {
  display: block;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
}
@media screen and (max-width: 768px) {
  .input {
    width: 100%;
  }
}
