.exp {
  padding: 5rem;
  background-image: url("../asset/home.jpeg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

}
.heading {
  font-size: 3.5rem;
  text-align: center;
  color: #fff;
}
.para {
  font-size: 1.8rem;
  color: #fff;
  margin-bottom: 4rem;
  line-height: 1.7;
}
.boxbtn {
  display: flex;
  gap: 5rem;
  padding: 5rem 0;
}
@media screen and (max-width: 768px) {
  .exp{
    padding: 3rem;
  }
  .boxbtn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

  }
}

@media screen and (max-width: 720px) {
  .heading {
    font-size: 3rem;

  }
  .para {
    font-size: 1.6rem;

  }
}