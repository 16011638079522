.burger {
  width: 2rem;
  height: 1.4rem;
  position: relative;
  cursor: pointer;
  z-index: 1000;
  display: none;
  margin-top: 1rem;
  /* margin-right: 5%; */

}
.icon {
  width: 100%;
  height: 0.25rem;
  background-color: #cd9933;
  position: absolute;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transform-origin: -1px;
}

.icon1 {
  top: 0;
}
.icon2 {
  top: 8px;
}
.icon3 {
  top: 16px;
}

.burger:hover .icon1 {
  top: 2px;
}

.burger:hover .icon3 {
  top: 14px;
}


.icontr1 {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
  }
  .icontr2 {
    transform: translate(100%);
    -webkit-transform: translate(100%);
    -moz-transform: translate(100%);
    -ms-transform: translate(100%);
    -o-transform: translate(100%);
    opacity: 0;
  }
  .icontr3 {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
  }
@media screen and (max-width: 768px) {
    .burger {
        display: block;
    }
}
