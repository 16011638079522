.card {
  width: 23rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: hidden;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}
.card:hover {
  transform: scale(1.3);
  -webkit-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
}
.imgbox {
  height: 15rem;
  width: 100%;
}
.img {
    width: 100%;
    height: 100%;
  }
  .h3 {
    text-transform: capitalize;
    font-size: 1.2rem;
  }

  .parabox {
    padding: 0 1rem;
  }
  .parabox p {
    font-size: 1.2rem;
  }
  .box-btn {
    padding: 2rem;
  }




@media screen and (max-width: 992px) {

    .imgbox {
      width: 100%;
    }
    .h3 {
      font-size: 1rem;
    }
  }

  @media screen and (max-width: 736px) {

    .card {
      width: 30em;
      margin-bottom: 4rem;

    }
    .imgbox {
      height: 20rem;
      width: 100%;
    }
    .img {
      width: 100%;
      height: 100%;
    }

  }