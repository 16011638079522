.btn:link,
.btn:visited {
  display: inline-block;
  font-size: 1.6rem;
  font-weight: bold;
  text-decoration: none;
  background-color: #fff;
  padding: 1.5rem 2.5rem;
  text-transform: uppercase;
  color: #cd9933;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}
.btn:hover {
  background-color: #dab46a;
  color: #fff;
  transform: translateY(2px);
  -webkit-transform: translateY(2px);
  -moz-transform: translateY(2px);
  -ms-transform: translateY(2px);
  -o-transform: translateY(2px);
}

@media screen and (max-width: 768px) {

}