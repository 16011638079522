* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  background: #fff;
  font-size: 62.5%;
}

body {
  margin: 0;
  text-align: center;
}

main {
  margin-top: 6rem;
}