.footer {
  background-color: #cd9933;
  padding: 4rem;

  display: flex;
  flex-direction: column;
}
.container {
  display: flex;
}
.logo {
  width: 8rem;
  height: 8rem;
  margin-bottom: 4rem;
}
.social {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.icon {
  width: 3rem;
}

.left {
  flex-basis: 25%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.right {
  flex: 1;
  padding: 1rem;

  display: flex;
  justify-content: space-around;
  align-items: center;
}

.item {
  list-style: none;
  text-align: start;
  padding: 0.2rem;
  font-size: 1.6rem;
}
.bold {
  font-weight: bold;
  text-transform: capitalize;
}
.link {
  text-decoration: none;
  text-transform: capitalize;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .list {
    width: 100%;
  }
  .footer {
    padding: 2rem;
  }
  .container {
    flex-direction: column;
  }
  .right {
   flex-direction: column;

  }
  .list {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
@media screen and (max-width: 590px) {

  .footer {
    padding: 1rem 0;
  }
  .item {
    font-size: 1.2rem;
    padding: 0;
  }
}
@media screen and (max-width: 420px) {
  .item {
    font-size: 1.2rem;
  }
}